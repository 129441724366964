<template>
  <!-- <base-section-alternating id="alternating-products"> -->
  <!-- <base-section-heading-alternating
      title="HVS Products Overview"
      class="px-2"
    >
    </base-section-heading-alternating> -->

  <v-container fluid>
    <v-row v-if="$vuetify.breakpoint.mdAndUp">
      <template v-for="(feature, i) in features">
        <v-col
          v-if="i === 0 || i === 1 || i === 4 || i === 5 || i === 8 || i === 9"
          id="color"
          class="my-0 py-0"
          :key="i"
          cols="12"
          sm="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="true"
          />
        </v-col>
        <v-col v-else class="ma-0 pa-0" :key="i" cols="12" sm="6">
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="true"
          />
        </v-col>
      </template>
    </v-row>
    <v-row v-else>
      <template v-for="(feature, j) in featuresMini">
        <v-col
          v-if="j === 0 || j === 2 || j === 4"
          id="color"
          :key="j"
          cols="12"
          sm="12"
        >
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="true"
          />
        </v-col>
        <v-col v-else :key="j" cols="12" sm="12">
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="true"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
  <!-- </base-section-alternating> -->
</template>

<script>
export default {
  name: "AlternatingProducts",
  computed: {
    featuresMini() {
      var tempArray = this.features;
      var indexes = [1, 2, 5, 6, 9, 10];
      tempArray = tempArray.filter(function (value, index) {
        return indexes.indexOf(index) == -1;
      });
      return tempArray;
    },
  },

  data: () => ({
    features: [
      {
        title: "Collect",
        text: "Securely gather sensitive data, while complying with industry standards such as GDPR, PCI-DSS, CCPA, and HIPAA to ensure the protection of personal and financial data.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Tokenize",
        text: "Replace sensitive data, such as credit card numbers and personally identifiable information (PII), with randomly generated tokens to enhance data security and maintain compliance with regulations like PCI-DSS and GDPR.",
      },
      {
        title: "Store",
        text: "Safely retain externally stored tokenized data using robust security measures and encryption to meet data security standards and comply with regulations such as GDPR, PCI-DSS, and HIPAA.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Send",
        text: "Transmit tokenized data to desired APIs for processing, leveraging secure communication protocols and adhering to industry regulations such as PSD2, Open Banking, NACHA, and PCI-DSS to ensure the secure transfer of sensitive data.",
      },
    ],
  }),
};
</script>
<style scoped>
#color {
  background-color: #ffffff;
  font-size: 1.9rem;
}

.v-col {
  font-size: 50px;
}
</style>
